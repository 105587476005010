import { connectStateResults } from 'react-instantsearch-dom';
import { useRouter } from 'next/router';
import Image from 'next/image';

function HomeSearchHits({ searchState, searchResults }) {
  const validQuery = searchState.query?.length >= 1;
  const router = useRouter();

  const searchDesign = (searchval) => {
    router.push(`/search/?query=` + searchval);
  };

  return (
    <>
      {searchResults?.hits.length > 0 && validQuery && (
        <>
          {searchResults.hits.map((hit) => (
            <li key={hit.objectID} onClick={() => searchDesign(hit.name)}>
              {hit.image && (
                <div className="searchlist-single-imgouter">
                  <Image
                    src={hit.image}
                    alt={hit.name}
                    layout="fill"
                    className="searchlist-single-img"
                  />
                </div>
              )}
              <span>{hit.name}</span>
            </li>
          ))}
        </>
      )}
    </>
  );
}

export default connectStateResults(HomeSearchHits);
