import Search from '@/components/pages/Home/Search';
import WelcomeMessage from '@/components/pages/Home/Unauthenticated/WelcomeMessage';
import CategoryList from '@/components/sections/CategoryList';

export default function HomeSliderBlock({
  welcomeText,
  featuredCategories,
  headline,
  subheadline,
}) {
  return (
    <>
      <div className="home-slider-block">
        <h2>{headline}</h2>
        <p>{subheadline}</p>
        {welcomeText && <WelcomeMessage welcomeText={welcomeText} />}

        <Search />
        <CategoryList featuredCategories={featuredCategories} />
      </div>
    </>
  );
}
