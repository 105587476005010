export const planDetails = {
  free: {
    benefits: [
      '850+ Templates and thousands of clipart',
      'Magic Autofill',
      'Share designs with friends',
      'Dozens of templates 100% FREE, others only $10',
    ],
  },
  monthly: {
    price: 15,
    benefits: [
      'Unlimited downloads all Flyers, Social media, Web banners & more',
      'Smart Resize. Resize a design to any size',
      'Unlimited saved designs',
      'Priority Support',
    ],
  },
};
