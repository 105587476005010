const SearchIcon = ({
  primaryColor = 'var(--primary-color)',
  secondaryColor = 'var(--secondary-color)',
}) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 1930">
        <g id="Group 79">
          <g id="Group 1261152818">
            <path
              id="Vector"
              d="M9.44377 18.8875C4.21337 18.8875 0 14.6742 0 9.44377C0 4.21337 4.21337 0 9.44377 0C14.6742 0 18.8875 4.21337 18.8875 9.44377C18.8875 14.6742 14.6742 18.8875 9.44377 18.8875ZM9.44377 1.45289C5.01246 1.45289 1.45289 5.01246 1.45289 9.44377C1.45289 13.8751 5.01246 17.4346 9.44377 17.4346C13.8751 17.4346 17.4346 13.8751 17.4346 9.44377C17.4346 5.01246 13.8751 1.45289 9.44377 1.45289Z"
              fill="url(#paint0_linear_1761_5006)"
            />
            <path
              id="Vector_2"
              d="M16.4784 15.4495L23.0018 21.9729L21.9746 23.0001L15.4512 16.4767L16.4784 15.4495Z"
              fill="url(#paint1_linear_1761_5006)"
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1761_5006"
          x1="-0.959367"
          y1="-6.09662"
          x2="16.8457"
          y2="-5.91956"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={secondaryColor} />
          <stop offset="1" stopColor={primaryColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1761_5006"
          x1="15.0676"
          y1="13.0122"
          x2="22.1856"
          y2="13.083"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={secondaryColor} />
          <stop offset="1" stopColor={primaryColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SearchIcon;
