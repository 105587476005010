import { ReactSVG } from 'react-svg';

import shield from '@/images/icons/shield.svg';

const CleanKosherBlock = () => {
  return (
    <>
      <div className="cleankosher-block p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="cleankosher-blockinner">
                <h4>
                  Safer for your{' '}
                  <label className="gradient-text">Neshama</label>
                </h4>
                <p>
                  than canva. Everything here is kosher to high Torah standards
                </p>
                <span>
                  <ReactSVG
                    beforeInjection={(svg) => {
                      svg.querySelectorAll('linearGradient').forEach((item) => {
                        item
                          .querySelector('stop:first-child')
                          .setAttribute('stop-color', 'var(--kg-secondary)');
                        item
                          .querySelector('stop:last-child')
                          .setAttribute('stop-color', 'var(--kg-primary)');
                      });
                    }}
                    src={shield.src}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CleanKosherBlock;
