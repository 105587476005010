import { connectStateResults } from 'react-instantsearch-dom';
import { useRouter } from 'next/router';

function HomeSearchCatHits({ searchState, searchResults }) {
  const validQuery = searchState.query?.length >= 1;
  const router = useRouter();

  const searchDesign = (searchval) => {
    router.push(`/search/?query=` + searchval);
  };

  return (
    <>
      {searchResults?.hits.length > 0 && validQuery && (
        <>
          {searchResults.hits.map((hit) => (
            <li key={hit.objectID} onClick={() => searchDesign(hit.name)}>
              {hit.name}
            </li>
          ))}
        </>
      )}
    </>
  );
}

export default connectStateResults(HomeSearchCatHits);
