import { checkImgUrl } from '@/datalayer/config';
import Image from 'next/image';

function ProductsListCard({ handleShow, product }) {
  return (
    <>
      <div className="featuredproduct-single" key={product.id}>
        <button onClick={() => handleShow(product)} key={product.id}>
          {product.thumbnail && (
            <div className="featuredproduct-single-imgouter">
              <Image
                src={checkImgUrl(product.thumbnail.id)}
                alt={product.title}
                layout="fill"
                className="featuredproduct-single-img"
              />
            </div>
          )}
          <h4>{product.title}</h4>
        </button>
      </div>
    </>
  );
}

export default ProductsListCard;
