import { Icon } from '@blueprintjs/core';
import { useEffect, useState } from 'react';

const WelcomeMessage = ({ welcomeText }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const item = localStorage.getItem('visited');
    if (item && item === 'yes') setShow(false);
    else {
      setShow(true);
    }
  }, []);

  const handleHide = () => {
    setShow(false);
    localStorage.setItem('visited', 'yes');
  };

  return (
    <div
      className={show ? 'welcome-message-block show' : 'welcome-message-block'}
    >
      <div className="welcome-message">
        <div className="close-icon" onClick={handleHide}>
          <Icon icon="cross" color="#fff" size={16} />
        </div>

        <div
          className="text-container"
          dangerouslySetInnerHTML={{ __html: welcomeText }}
        ></div>
      </div>
    </div>
  );
};

export default WelcomeMessage;
