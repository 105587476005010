import ProductsList from '@/components/sections/ProductsList';
import sprinkles from '@/images/icons/sprinkles.svg';
import sprinkles2 from '@/images/icons/sprinkles-2.svg';
import SVGTool from '@/components/utility/SVGTool';

export default function FeaturedTemplates({ featuredProducts }) {
  return (
    <>
      <div className="featured-templates-block p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="home-products-block">
                <div className="background">
                  <div className="sprinkles">
                    <SVGTool src={sprinkles.src} />
                  </div>
                  <div className="sprinkles second">
                    <SVGTool src={sprinkles2.src} />
                  </div>
                </div>
                <h3>
                  Featured <label>Templates</label>
                </h3>
                <p>of thousands of graphics. Try one out!</p>
                <ProductsList products={featuredProducts} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
