import { createContext, useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import ApiService from 'services/ApiService';

export const HomePageContext = createContext();

export function HomePageProvider({ children }) {
  const [projectsData, setProjectsData] = useState('');
  const [activeSubscription, setActiveSubscription] = useState(false);

  const { data: session, status } = useSession();

  const checkActiveSubscription = async () => {
    try {
      const { data } = await ApiService.instance.get(
        '/api/stripe/checkActiveSubscription',
      );
      if (data.hasActiveSubscription) setActiveSubscription(true);
    } catch (e) {
      setActiveSubscription(false);
    }
  };

  const getOwnProjects = async () => {
    if (session != null) {
      const { data } = await ApiService.instance.post('/api/gethomeprojects', {
        userid: session.user.id,
      });
      setProjectsData(data.data.saveddesigns);
    }
  };

  useEffect(() => {
    if (status === 'authenticated') {
      checkActiveSubscription();
      getOwnProjects();
    }
  }, [session, status]);

  return (
    <HomePageContext.Provider value={{ projectsData, activeSubscription }}>
      {children}
    </HomePageContext.Provider>
  );
}
