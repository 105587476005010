import HomeSliderBlock from '@/components/pages/Home/HomeSliderBlock';
import ProSubscriptionBlock from '@/components/pages/Home/Authenticated/ProSubscriptionBlock';
import RecentDesignsBlock from '@/components/pages/Home/Authenticated/RecentDesignsBlock';
import ProjectsBlock from '@/components/pages/Home/Authenticated/ProjectsBlock';
import { HomePageContext } from 'context/HomePageContext';
import { useSiteInfo } from '@/context/SiteInfoContext';
import { useContext } from 'react';
import SidebarContainer from '@/components/layouts/Sidebar/SidebarContainer';

export default function Authenticated({
  featuredCategories,
  featuredProducts,
  trendingProducts,
  latestProducts,
  headline,
  subheadline,
}) {
  const { projectsData, activeSubscription } = useContext(HomePageContext);
  const { siteInfo } = useSiteInfo();
  const subscription = siteInfo ? siteInfo.pro_subscription : null;

  return (
    <>
      <SidebarContainer>
        <div className="pgcontent userhomepgcontent">
          <HomeSliderBlock
            featuredCategories={featuredCategories}
            headline={headline}
            subheadline={subheadline}
          />
          {projectsData.length > 0 && (
            <RecentDesignsBlock projectsData={projectsData} />
          )}
          <ProjectsBlock
            title={'Featured Templates'}
            subtitle={'of thousands of graphics. Try one out! '}
            products={featuredProducts}
          />

          {trendingProducts?.length > 0 && (
            <ProjectsBlock
              title={'Trending Templates'}
              subtitle={'of thousands of graphics. Try one out! '}
              products={trendingProducts}
            />
          )}

          <ProjectsBlock
            title={'New Design Templates'}
            subtitle={'Check some of our newest creations!'}
            products={latestProducts}
          />

          {!activeSubscription && subscription && <ProSubscriptionBlock />}
        </div>
      </SidebarContainer>
    </>
  );
}
