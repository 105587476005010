import { ReactSVG } from 'react-svg';

const SVGTool = ({ src }) => {
  return (
    <ReactSVG
      beforeInjection={(svg) => {
        svg.querySelectorAll('linearGradient').forEach((item) => {
          item
            .querySelector('stop:first-child')
            .setAttribute('stop-color', 'var(--kg-secondary)');
          item
            .querySelector('stop:last-child')
            .setAttribute('stop-color', 'var(--kg-primary)');
        });
      }}
      src={src}
    />
  );
};

export default SVGTool;
