const DiamondIcon = () => {
  return (
    <svg
      width="51"
      height="41"
      viewBox="0 0 51 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.57591 2.61975L12.9847 12.2664H0.904053L8.57591 2.61975ZM12.0375 0.0163727H22.4924L16.4249 9.61527L12.0375 0.0163727ZM19.7866 12.2678L25.9055 2.58562L32.023 12.2637L19.7866 12.2678ZM32.7987 15.9368L25.9055 40.9485L19.0094 15.9354L32.7987 15.9368ZM35.4617 9.61255L29.3971 0.0163727H39.8491L35.4617 9.61255ZM43.2293 2.6211L50.9041 12.2678H38.8205L43.2293 2.6211ZM15.2549 15.9368L21.961 40.2635L2.27269 15.9368H15.2549ZM29.8428 40.2635L36.5489 15.9368H49.5297L29.8428 40.2635Z"
        fill="url(#paint0_linear_3954_12521)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3954_12521"
          x1="-1.63563"
          y1="-13.1959"
          x2="45.4966"
          y2="-12.6234"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--kg-secondary)" />
          <stop offset="1" stopColor="var(--kg-primary)" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DiamondIcon;
