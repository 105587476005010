import React, { useContext } from 'react';
import { ProductModalContext } from 'context/ProductModalContext';
import ProductsListCard from '../card/ProductsListCard';

const ProductsList = ({ products }) => {
  const { handleShow } = useContext(ProductModalContext);

  return (
    <div className="featuredproducts">
      {products.length ? (
        products.map((_product) => (
          <ProductsListCard
            product={_product}
            handleShow={handleShow}
            key={_product.id}
          />
        ))
      ) : (
        <div>No Product Found</div>
      )}
    </div>
  );
};

export default ProductsList;
