import Mainlayout from '@/components/layouts/Mainlayout';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { checkURLforErrors } from 'utils/checkURLforErrors';
import { HomePageProvider } from 'context/HomePageContext';
import Unauthenticated from '@/components/pages/Home/Unauthenticated';
import Authenticated from '@/components/pages/Home/Authenticated';
import datasource from '@/datalayer/index';
import { ProductModalProvider } from 'context/ProductModalContext';
import { useSiteInfo } from '@/context/SiteInfoContext';
import OpenGraphContainer from '@/components/utility/OpenGraphContainer';

export default function Home({
  featuredProducts,
  trendingProducts,
  latestProducts,
  featuredCategories,
  welcomeText,
  headline,
  subheadline,
}) {
  const { status } = useSession();
  const router = useRouter();

  const { siteInfo } = useSiteInfo();

  useEffect(() => {
    checkURLforErrors(router);
  }, []);

  return (
    <>
      <OpenGraphContainer
        title={siteInfo.page_title}
        description={subheadline}
      />
      {status === 'authenticated' ? (
        <Authenticated
          featuredProducts={featuredProducts}
          featuredCategories={featuredCategories}
          latestProducts={latestProducts}
          trendingProducts={trendingProducts}
          headline={headline}
          subheadline={subheadline}
        />
      ) : (
        <Unauthenticated
          welcomeText={welcomeText}
          featuredCategories={featuredCategories}
          featuredProducts={featuredProducts}
          headline={headline}
          subheadline={subheadline}
        />
      )}
    </>
  );
}

export async function getServerSideProps({ req, res, query }) {
  try {
    const { product } = query;
    if (product) {
      return {
        redirect: {
          permanent: false,
          destination: `/product/${product}`,
        },
      };
    }
    const domain = req.headers.host;
    const data = await datasource.getHomepageProducts(domain);

    const featuredProducts = data.Sites[0].homepage.featured
      .filter((item) => item.products_id) // Filter out items with null products_id
      .map((item) => ({
        id: item.products_id.id,
        thumbnail: item.products_id.thumbnail,
        title: item.products_id.title,
        slug: item.products_id.slug,
        description: item.products_id.description,
        price: item.products_id.price,
        category: item.products_id.category,
        product_type: {
          name: item.products_id.product_type
            ? item.products_id.product_type.name
            : 'Flyer',
        },
        related_products: item.products_id.related_products,
        product_language: item.products_id.product_language,
        date_created: item.products_id.date_created,
      }));

    const trendingProducts =
      data?.trending_designs &&
      Array.isArray(data?.trending_designs) &&
      data?.trending_designs.length > 0
        ? data?.trending_designs[0]?.products
            .filter((item) => item.products_id) // Filter out items with null products_id
            .map((item) => ({
              id: item.products_id.id,
              thumbnail: item.products_id.thumbnail,
              title: item.products_id.title,
              slug: item.products_id.slug,
              description: item.products_id.description,
              price: item.products_id.price,
              category: item.products_id.category,
              product_type: {
                name: item.products_id.product_type
                  ? item.products_id.product_type.name
                  : 'Flyer',
              },
              related_products: item.products_id.related_products,
              product_language: item.products_id.product_language,
              date_created: item.products_id.date_created,
            }))
        : [];

    const featuredCategories = data.Sites[0].homepage.featured_categories
      .filter((item) => item.Product_categories_cat_id) // Filter out items with null Product_categories_cat_id
      .map((item) => ({
        cat_id: item.Product_categories_cat_id.cat_id,
        category_name: item.Product_categories_cat_id.category_name,
        category_icon: item.Product_categories_cat_id.category_icon,
        slug: item.Product_categories_cat_id.slug,
        icon: item.Product_categories_cat_id.icon,
      }));

    return {
      props: {
        welcomeText: data.Sites[0].homepage.welcome_text,
        featuredProducts,
        featuredCategories,
        latestProducts: data.products,
        trendingProducts: trendingProducts,
        headline: data.Sites[0].homepage.headline,
        subheadline: data.Sites[0].homepage.subheadline,
      },
    };
  } catch (e) {
    return {
      props: {
        welcomeText: '',
        featuredProducts: [],
        featuredCategories: [],
        latestProducts: [],
        trendingProducts: [],
      },
    };
  }
}

Home.getLayout = function getLayout(pageProps, page) {
  return (
    <Mainlayout pageProps={pageProps}>
      <ProductModalProvider>
        <HomePageProvider>{page}</HomePageProvider>
      </ProductModalProvider>
    </Mainlayout>
  );
};
