import Link from 'next/link';
import resize from '@/images/icons/resize.svg';
import star from '@/images/icons/star.svg';
import verified from '@/images/icons/verified.svg';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { getSubscriptionURL } from 'lib/stripeHelper';
import DiamondIcon from 'assets/jsx-icons/DiamondIcon';
import { ReactSVG } from 'react-svg';
import semi_circle from '@/images/icons/semi-circle.svg';
import dot_box from '@/images/icons/dot-box.svg';
import SVGTool from '@/components/utility/SVGTool';
import { useSiteInfo } from '@/context/SiteInfoContext';
import { planDetails } from '@/constants/planDetails';

export default function ProSubscriptionBlock() {
  const router = useRouter();
  const { siteInfo } = useSiteInfo();

  const processSubscribe = async () => {
    try {
      const url = await getSubscriptionURL();
      router.push(url);
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <>
      <div className="kg-feature-singleouter">
        <div className="background">
          <SVGTool src={semi_circle.src} />
        </div>
        <div className="dot-box">
          <SVGTool src={dot_box.src} />
        </div>
        <div className="kg-feature-single userhome-tokgpro">
          <h4>
            <DiamondIcon />
            Pro
          </h4>
          <p>
            Unlock additional features and benefits by upgrading to a Pro
            Subscription
          </p>
          <ul>
            <li>
              <span>
                <ReactSVG src={star.src} />
              </span>
              Unlimited downloads{' '}
              <label>On all flyers, social media & web graphics</label>
            </li>
            <li>
              <span>
                <ReactSVG src={resize.src} />
              </span>
              Smart resize <label>Resize a design to any size</label>
            </li>
            <li>
              <span>
                <ReactSVG src={verified.src} />
              </span>
              Unlimited saved designs
            </li>
          </ul>
          <p>And more!</p>
          <span className="light-text pro-price-text top font-20">
            Only ${siteInfo?.pro_price || planDetails.monthly.price}/month!
          </span>
          <div className="btn-container">
            <button className="button-contained lg" onClick={processSubscribe}>
              Subscribe Now
            </button>
            <Link href="/pro">
              <a className="button-outlined lg">
                <span>Learn More</span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
