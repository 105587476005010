export const getDiscountedBaseDesignPrice = (products, siteInfo) => {
  const designPrices = products?.products?.reduce((acc, product) => {
    acc[product.price] = (acc[product.price] || 0) + 1;
    return acc;
  }, {}) || { 10: 6 };

  const baseDesignPrice = Object.keys(designPrices).reduce((a, b) =>
    designPrices[a] > designPrices[b] ? a : b,
  );

  const discountedDesignPrice =
    baseDesignPrice -
    (siteInfo.discount_value
      ? siteInfo.discount_value
      : siteInfo.discount
        ? (siteInfo.discount * baseDesignPrice) / 100
        : 0);

  return discountedDesignPrice.toFixed(2).replace(/\.00$/, '');
};
