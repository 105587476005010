import UnityBoxLogo from '@/images/homepage/unitybox-logo.svg';
import Image from 'next/image';

const PartnersBlock = () => {
  return (
    <>
      <div className="partners-block p-100" id="partners">
        <div className="section-container container">
          <div className="text-container">
            <h4>Our Partners</h4>
          </div>
          <div className="image-container">
            <div className="partners-card">
              <div className="card-image">
                <Image src={UnityBoxLogo} alt="Unity Box Logo" />
              </div>
              <div className="card-text">The out-of-the-box Charity Box</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnersBlock;
