import { connectSearchBox } from 'react-instantsearch-dom';
import { useRouter } from 'next/router';
import searchicon from '@/images/searchicon.svg';
import Image from 'next/image';
import SearchIcon from 'assets/jsx-icons/SearchIcon';
import { useSiteInfo } from '@/context/SiteInfoContext';

function HomeSearchBox({ refine }) {
  const router = useRouter();
  const { siteInfo } = useSiteInfo();

  const findSearch = (e) => {
    e.preventDefault();
    router.push(`/search/?query=` + e.target.search.value);
  };

  return (
    <form
      id="search"
      className="home_search_form"
      onSubmit={(e) => findSearch(e)}
    >
      <div className="search-box-container">
        <input
          type="text"
          className="search-bar"
          name="search"
          onChange={(e) => refine(e.currentTarget.value)}
        />
        <span className="input-group-btn">
          <button type="submit" className="btn btn-default btn-lg">
            <span className="homesearch-icon-desktop">
              <Image alt="searchicon" src={searchicon} />
            </span>
            <span className="homesearch-icon-mobile">
              <SearchIcon
                primaryColor={siteInfo.primary_color}
                secondaryColor={siteInfo.secondary_color}
              />
            </span>
          </button>
        </span>
      </div>
    </form>
  );
}

export default connectSearchBox(HomeSearchBox);
