import Link from 'next/link';
import React, { useMemo, useState } from 'react';
import { checkImgUrl } from '@/datalayer/config';
import { ReactSVG } from 'react-svg';
import { useSiteInfo } from '@/context/SiteInfoContext';
import { ChevronRightIcon } from '@chakra-ui/icons';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CategoryList = ({ featuredCategories }) => {
  const { siteInfo } = useSiteInfo();

  const primaryColor = siteInfo.primary_color;
  const secondaryColor = siteInfo.secondary_color;
  const [showMore, setShowMore] = useState(false);

  const finalCategories = useMemo(() => {
    return featuredCategories
      .filter((item) => item.category_icon)
      .sort((a, b) => b.sort - a.sort)
      .map((item) => ({
        ...item,
        image_url: checkImgUrl(item.category_icon?.filename_disk ?? ''),
      }));
  }, []);

  return (
    <div className="category-section">
      {finalCategories.map((_category, i) => {
        if ((!showMore && i < 7) || showMore)
          return (
            <Link key={i} href={`/${_category.slug}`}>
              <div className="category-block">
                <ReactSVG
                  className="category-svg"
                  beforeInjection={(svg) => {
                    svg.querySelectorAll('linearGradient').forEach((item) => {
                      if (secondaryColor)
                        item
                          .querySelector('stop:first-child')
                          .setAttribute('stop-color', secondaryColor);
                      if (primaryColor)
                        item
                          .querySelector('stop:last-child')
                          .setAttribute('stop-color', primaryColor);
                    });
                  }}
                  src={_category.image_url}
                />
                <span>{_category.category_name}</span>
              </div>
            </Link>
          );
      })}
      {!showMore ? (
        <div
          className="category-block custom-block"
          onClick={() => setShowMore(true)}
        >
          <span>Show more</span>
          <ChevronRightIcon />
        </div>
      ) : null}
    </div>
  );
};

export default CategoryList;
