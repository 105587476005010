import Image from 'next/image';
import magicautofillblockimg from '@/images/homepage/magicautofill-block-gif.gif';

const MagicAutofillBlock = () => {
  return (
    <>
      <div className="magicautofill-block p-100 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="magicautofill-blockinner">
                <div className="magicautofill-block-left">
                  <h4>
                    Magic <span className="gradient-text"> Autofill</span>{' '}
                    <label className="black-pill">Beta</label>
                  </h4>
                  <p>Designs are auto-populated with your data</p>
                </div>
                <div className="magicautofill-block-right">
                  <Image
                    alt="magicautofillblockimg"
                    src={magicautofillblockimg}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MagicAutofillBlock;
